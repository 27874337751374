<template src="./tutorial.html"></template>

<script>
export default {
  data() {
    return {};
  },
  methods: {
    download() {},
  },
};
</script>

<style lang="scss" src="./tutorial.scss">
</style>